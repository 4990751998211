/******************************************************************

Helpers

******************************************************************/

.block {
  display: block;
}

.italic {
    font-style:italic;
}

.hidden {
    display:none !important;
}

.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
    *zoom: 1;
}

.overflow-fix {
    overflow: hidden;
}

.nounderline {
  text-decoration: none !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.lowercase {
  text-transform: lowercase !important;
}

small {
    font-size: 80%;
}

strong {
    font-weight: 600;
}

.container {
    position: relative;
}

.image-circle {
    border-radius: 50%;
}

.image-responsive {
  max-width: 100%;
  height: auto;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

// HW accelerator
.hw {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
}

.last {
    margin-right: 0 !important;
}

/*
 * Image replacement
 */
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  /* IE 6/7 fallback */
  *text-indent: -9999px;
}

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

.text-hide {
  color: transparent;
  font-size: 0;
  text-shadow: none;
  vertical-align: middle;
}

.fixed {
    position: fixed;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.full-width {
    width: 100%;
}
.half-width {
    width: 50%;
}

.center {
    margin: 0 auto;
}

.smooth {
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}