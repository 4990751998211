/******************************************************************

Alerts

Based on: Bones - github.com/eddiemachado/bones

******************************************************************/

// alerts and notices
%alert {
  background: #fcf8e3;
  color: #8a6d3b;
}

.alert-help {
  @extend %alert;
}