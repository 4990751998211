
@font-face {
  font-family: 'icomoon';
  src:  url('./../fonts/icomoon.eot?4progw');
  src:  url('/../fonts/icomoon.eot?4progw#iefix') format('embedded-opentype'),
    url('/../fonts/icomoon.ttf?4progw') format('truetype'),
    url('/../fonts/icomoon.woff?4progw') format('woff'),
    url('/../fonts/icomoon.svg?4progw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 34px;
  position: relative;
	top:9px;
}

.icon-1:before { /* butters */
  content: "\e900";
}
.icon-5:before { /* carrier-oils */
  content: "\e901";
}
.icon-6:before { /* preservatives */
  content: "\e902";
}
.icon-3:before { /* extras */
  content: "\e903";
}
.icon-essential-oils:before { /* essential oils */
  content: "\e904";
}
.icon-2:before { /* emulsifiers */
  content: "\e905";
}

.md-checked [class^="icon-"] span:before, .highlight {
color: rgba(33,150,243,0.87)!important;
color: black!important;
}

body .icon-9 .path1:before { /* facial-cleanser */
  content: "\e906";
  color: rgb(237, 236, 228)!important;
}
.icon-9 .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
.icon-9 .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
.icon-7 .path1:before { /* clay mask */
  content: "\e909";
  color: rgb(35, 35, 35);
}
.icon-7 .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
.icon-7 .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
.icon-7 .path4:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(198, 196, 171);
}
body .icon-7 .path4:before {
  color: rgb(198, 196, 171)!important;
}
.icon-12 .path1:before { /* ultra-glow-serum */
  content: "\e90d";
  color: rgb(35, 35, 35);
}
.icon-12 .path2:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
.icon-12 .path3:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
.icon-12 .path4:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
.icon-12 .path5:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
body .icon-12 .path6:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(212, 191, 82)!important;
}
.icon-11 .path1:before { /* intense-hydration-creme */
  content: "\e913";
  color: rgb(35, 35, 35);
}
.icon-11 .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
body .icon-11 .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(70, 98, 162)!important;
}
.icon-10 .path1:before { /* hydrolat-toner */
  content: "\e916";
  color: rgb(35, 35, 35);
}
.icon-10 .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
body .icon-10 .path3:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(141, 198, 136)!important;
}
.icon-8 .path1:before { /* exfoliating-polish */
  content: "\e919";
  color: rgb(35, 35, 35);
}
.icon-8 .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(35, 35, 35);
}
body .icon-8 .path3:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(194, 80, 193)!important;
}
