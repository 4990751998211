/******************************************************************

Site Name: AngularJS Boilerplate
Code: Jozef Butko - jozefbutko.com/resume
Date: Mar 2015

******************************************************************/

// normalize: http://necolas.github.io/normalize.css/
@import "partials/normalize";

// Sass variables
@import "partials/variables";

// Helpers
@import "partials/helpers";

// typography
@import "partials/typography";

// Sass functions
@import "partials/functions";

// import mixins
@import "partials/mixins";

// the grid
@import "partials/grid";

// icons
@import "partials/icons";


/*********************
IMPORTING MODULES
*********************/

// alert boxes
@import "modules/alerts";

// buttons
@import "modules/buttons";

// forms (inputs & labels)
@import "modules/forms";

// iphone
@import "modules/iphone";

// pattern lock
@import "modules/patternLock";


/*********************
BASE (MOBILE) SIZE
*********************/

// styles in base.scss
@import "breakpoints/base";

/*********************
LARGER MOBILE DEVICES
*********************/
@media only screen and (min-width: 481px) {

	// styles in 481up.scss
	@import "breakpoints/481up";

} // end of media query

/*********************
TABLET & SMALLER LAPTOPS
*********************/
@media only screen and (min-width: 768px) {

	// styles in 768up.scss
	@import "breakpoints/768up";

} // end of media query

/*********************
DESKTOP
*********************/
@media only screen and (min-width: 1030px) {

	// styles in 1030up.scss
	@import "breakpoints/1030up";

} // end of media query

/*********************
LARGE VIEWING SIZE
*********************/
@media only screen and (min-width: 1240px) {

	// styles in 1240up.scss
	@import "breakpoints/1240up";

} // end of media query

/*********************
RETINA (2x RESOLUTION DEVICES)
*********************/
// @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
//        only screen and (min--moz-device-pixel-ratio: 1.5),
//        only screen and (min-device-pixel-ratio: 1.5) {

// 	// styles in 2x.scss
// 	@import "breakpoints/2x";

// } // end of media query

/*********************
PRINT STYLESHEET
*********************/
@media print {

	// styles in print.scss
	@import "partials/print";

}


/*********************
ACCORDION STYLING
*********************/

ul.collapsible {
	list-style: none;
	padding: 0;
	box-shadow: none;
	border-top: none;
	border-bottom: 1px solid #ddd;
	background: white;
	margin: 0;


	li {
		border-top: solid 1px #ddd;
		border-bottom: none;
		margin: 0;
		border-left:solid 2px transparent!important;
		&.active {
			border-left-color:
			#0dc2d5!important;
		}
		.toggleProduct, .toggleIngredient, .toggleConcern {
			cursor: pointer;
		}
		.toggleProduct {
			position: relative;
			&.product-ingredients {
			    padding-left: 26px;
			    position: relative;
			    padding: 10px 5px 10px 30px;
					span.icon--category.icon--category__products.ng-scope {
						top: 4px;
						position: absolute;
						z-index: 999;
						left: 0px;
					}
			}

		}
		a.toggle {
			width: 100%;
			display: block;
			background: #f8f8f8;
			color: #888;
			padding: .75em;
			border-radius: 0.15em;
			@transition(all .3s ease);
		}
		&.header {
			min-height: 50px;
			line-height: 50px;
			font-size: $small;
			.toggleConcern {
				.subtitle, .content {
					display: none;
				}
			}

			&.active{
				.title {
					font-size: 20px;
				}
				.toggleConcern {
					.subtitle {
						margin-left: 38px!important;
						margin-top: -17px!important;
						color: #888;
						display:block;
					}
					.content {
						display:none;
					}
				}
				.inner {
					.subtitle {
						display:none;
					}
					.content {
						h5.oils {
							font-style: italic;
					    text-align: center;
					    font-size: 13px!important;
						}
						hr {
							width: 90px;
						}
						strong {
							font-weight: bold;
							font-size: 120%;
						}
					}
				}
			}
			.inner {
				line-height:21px;

			}
			.toggleIngredient + .inner.show {
				padding-bottom: 30px;
			}
			.ingredient {
				min-height: 20px;
				line-height: 20px;
			}
		}
	}
	.collapsible-header {
		height:auto!important;
		border-bottom: none;
	}
  .inner {
      padding-left: 1em;
      overflow: hidden;
      display: none;
			padding-right:15px;
      &.show {
        /*display: block;*/
      }
  }
}

.container {
	padding: 0!important;
	max-width: 768px!important;
	width: 100%!important;
}

.md-tab {
	padding: 12px 15px!important;
}

.md-tab {
	font-size:11px!important;
}

ingredient {
	display:block;
	padding-bottom: 20px;
	li {
		font-size:12px;
		.toggle{font-style:italic;}
	}
}


.icon--category  span {
	font-size: 22px;
	left: 2px;
	top: -6px;
}
.icon--other-products {
	float:left;
	width:100%;
	margin-left: 10px;
	span {
		font-size:30px;
		&.title {
			font-size: 12px!important;
			font-style: italic;
			margin-left: -5px;
		}
	}
}

.icon--other-products__title {
	font-size: 13px;
  width: 100%;
  text-align: left;
  font-style: italic;
}
p {
	text-align:justify;
	word-break: initial;
	word-wrap: normal!important;
	hyphens: none;
}
.description--text {
	margin-bottom: 0;
	margin-bottom:0;
	padding-bottom:15px;
p {
		margin-bottom:0;
		margin-bottom: 0;
	}
}

.icon--category {
	float:left;
	margin-right:5px;
}
span.icon--category {
  //  padding-bottom: 10px;
  //  display: inline-block;
	//	margin-top:-10px;
}
h5 {
	font-size: 16px!important;
    margin: 0px!important;
		padding-bottom:10px;
}

md-input-container ._md-placeholder, md-input-container label:not(.md-no-float):not(._md-container-ignore) {
	overflow:visible!important;
	width:auto!important;
}

md-input-container.md-icon-float > label {margin-left:0!important;}

md-input-container label {
    position: relative;
    top: 10px!important;
    left: 8px!important;
}
md-input-container.md-icon-float > input {
	padding-top:0!important;
}

.clearSearch {
    width: 45px!important;
    right: 0!important;
    position: absolute;
    top: 30px!important;
		background:white!important;
		border:none!important;
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
    color: #0dc2d5 !important;
    background: #0dc2d5 !important;
}

md-tabs[md-border-bottom] md-tabs-wrapper {
	border-width:0!important;
}

.product-range {margin-right:20px;}
header {
	background: #aaa;
	float:left;
	width:100%;
	min-height:100px;
	img {
	    width: 60px;
	    margin: 10px auto 0;
	    display: block;
		}
		h2 {
			margin: 8px 0;
			text-align: center;
			font-size: 22px;
			line-height:22px;
			font-family: 'Oswald', sans-serif;
			font-weight: 200;
			color: white;
			letter-spacing: 6px;
		}
		h3 {
			color: white;
	    font-size: 12px;
	    text-align: center;
	    margin: 0;
	    line-height: 12px;
	    letter-spacing: 3px;
	    font-weight: 100;
			margin-bottom: 10px;
		}
}

md-content {
	background:transparent;
		padding: 0!important;
		 float: left;
		 width: 100%;
		 color: #333;

}

.main {
	background:url(http://bluelotus.co.uk/wp-content/uploads/2017/10/india-bg-texture-small-1.jpg) repeat fixed!important;
  max-width:9999px!important;
	margin:0!important;
	padding:0!important;
	float:left;
	width:100%!important;
	}

md-tabs-canvas {background: white;}

md-tabs[md-dynamic-height] md-tabs-content-wrapper md-tab-content {padding:20px;}


	body {float:left;width:100%;
		background: url(http://bluelotus.co.uk/wp-content/uploads/2017/10/india-bg-texture-small-1.jpg) repeat !important;}



	//.iphone {margin: -75px auto 0;}
	.iphone-screen {background: url(http://bluelotus.co.uk/wp-content/uploads/2017/10/india-bg-texture-small-1.jpg) repeat !important;}

	@media only screen
	and (max-device-width : 667px) {


	.top-bar, .iphone-top, .camera, .sensor, .speaker, .buttons, .bottom-bar, .iphone-bottom {
		display:none;
	}
	.iphone {transform:none; width:100%;height:auto;border:none;box-shadow:none;background:none;margin:0 auto!important;padding:0!important;}
	.iphone-screen {border:none;width:100%;height:auto;overflow:auto;float:left;}

}

// LOADER
.main-content{position:relative;z-index:100;}
header {position:relative;
@transition(all 2s ease);
}

md-content {
	 -webkit-overflow-scrolling: touch;
}
md-content.loading {

	-webkit-overflow-scrolling: auto;
}
#loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
		z-index:101;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
  //  border-top-color: #3498db;
		border-top-color: #0dc2d5;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    //border-top-color: #e74c3c;
		border-top-color: #61c7d2;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
  //  border-top-color: #f9c922;
		border-top-color: #9de7ef;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}


body {
	//overflow:hidden;

}

.contentRender {opacity:0;}
