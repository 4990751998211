/******************************************************************

Variables

******************************************************************/

$black:             #231f20;
$white:             #fff;

$light-gray:        #f8f9fa;
$gray:              #eaedf2;
$text-color:        #5c6b80;

$link-color:        $black;
$link-hover:        darken($link-color, 9%);

$border-color:      #ccc;
$disabled-gray:     #cfcfcf;
$alert-red:					red;
$alert-green:				#645564;
$alert-blue:				blue;